import commonAction from 'actions/commonAction';
import DisclosuresApi from 'api/generated/DisclosuresApi';
import { GetBrokerCompensationLinkQuery } from 'api/generated/models';

export const GET_BROKER_COMPENSATION_LINK_ACTION = 'GET_BROKER_COMPENSATION_LINK';

export const getBrokerCompensationLink = (state: string, carrier: string) =>
    commonAction(
        async () =>
            new DisclosuresApi().getBrokerCompensationLink(
                new GetBrokerCompensationLinkQuery({ carrier, state })
            ),
        GET_BROKER_COMPENSATION_LINK_ACTION
    );
